<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="发货单" prop="(id">
                <a-input v-model="rowData.id" placeholder="发货单"></a-input>
            </a-form-model-item>
            <a-form-model-item label="（0代表三菱的发货单）所属经销商id" prop="(orderBelongsId">
                <a-input v-model="rowData.orderBelongsId" placeholder="（0代表三菱的发货单）所属经销商id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="经销商id" prop="(dealerId">
                <a-input v-model="rowData.dealerId" placeholder="经销商id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="发货单号" prop="(shipCode">
                <a-input v-model="rowData.shipCode" placeholder="发货单号"></a-input>
            </a-form-model-item>
            <a-form-model-item label="订单号" prop="(orderCode">
                <a-input v-model="rowData.orderCode" placeholder="订单号"></a-input>
            </a-form-model-item>
            <a-form-model-item label="k3wise销售订单" prop="(finterid">
                <a-input v-model="rowData.finterid" placeholder="k3wise销售订单"></a-input>
            </a-form-model-item>
            <a-form-model-item label="发货总数(创建发货单时的数量)" prop="(shipNum">
                <a-input v-model="rowData.shipNum" placeholder="发货总数(创建发货单时的数量)"></a-input>
            </a-form-model-item>
            <a-form-model-item label="实际发货数(erp回传)" prop="(realShipNum">
                <a-input v-model="rowData.realShipNum" placeholder="实际发货数(erp回传)"></a-input>
            </a-form-model-item>
            <a-form-model-item label="快递公司id" prop="(deliveryId">
                <a-input v-model="rowData.deliveryId" placeholder="快递公司id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="快递公司名称" prop="(deliveryName">
                <a-input v-model="rowData.deliveryName" placeholder="快递公司名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="快递公司编码" prop="(deliveryCode">
                <a-input v-model="rowData.deliveryCode" placeholder="快递公司编码"></a-input>
            </a-form-model-item>
            <a-form-model-item label="快递单号" prop="(deliveryNum">
                <a-input v-model="rowData.deliveryNum" placeholder="快递单号"></a-input>
            </a-form-model-item>
            <a-form-model-item label="物流备注" prop="(deliveryRemark">
                <a-input v-model="rowData.deliveryRemark" placeholder="物流备注"></a-input>
            </a-form-model-item>
            <a-form-model-item label="省_id" prop="(provinceId">
                <a-input v-model="rowData.provinceId" placeholder="省_id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="省" prop="(province">
                <a-input v-model="rowData.province" placeholder="省"></a-input>
            </a-form-model-item>
            <a-form-model-item label="城市_id" prop="(cityId">
                <a-input v-model="rowData.cityId" placeholder="城市_id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="市" prop="(city">
                <a-input v-model="rowData.city" placeholder="市"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区_id" prop="(districtId">
                <a-input v-model="rowData.districtId" placeholder="区_id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区" prop="(district">
                <a-input v-model="rowData.district" placeholder="区"></a-input>
            </a-form-model-item>
            <a-form-model-item label="详细街道地址" prop="(street">
                <a-input v-model="rowData.street" placeholder="详细街道地址"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收货人类型 1:个人 2:公司" prop="(shipType">
                <a-input v-model="rowData.shipType" placeholder="收货人类型 1:个人 2:公司"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收货人电话" prop="(phone">
                <a-input v-model="rowData.phone" placeholder="收货人电话"></a-input>
            </a-form-model-item>
            <a-form-model-item label="附件" prop="(imgUrl">
                <a-input v-model="rowData.imgUrl" placeholder="附件"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人" prop="(createUser">
                <a-input v-model="rowData.createUser" placeholder="创建人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建人id" prop="(createUserId">
                <a-input v-model="rowData.createUserId" placeholder="创建人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="创建时间" prop="(createTime">
                <a-input v-model="rowData.createTime" placeholder="创建时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人" prop="(modifyUser">
                <a-input v-model="rowData.modifyUser" placeholder="修改人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改人id" prop="(modifyUserId">
                <a-input v-model="rowData.modifyUserId" placeholder="修改人id"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改时间" prop="(modifyTime">
                <a-input v-model="rowData.modifyTime" placeholder="修改时间"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否删除" prop="(flagDel">
                <a-input v-model="rowData.flagDel" placeholder="是否删除"></a-input>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="(remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
            <a-form-model-item label="版本号" prop="(version">
                <a-input v-model="rowData.version" placeholder="版本号"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收货人" prop="(consignee">
                <a-input v-model="rowData.consignee" placeholder="收货人"></a-input>
            </a-form-model-item>
            <a-form-model-item label="实际支付价" prop="(actualPrice">
                <a-input v-model="rowData.actualPrice" placeholder="实际支付价"></a-input>
            </a-form-model-item>
            <a-form-model-item label="件数" prop="(caseNumber">
                <a-input v-model="rowData.caseNumber" placeholder="件数"></a-input>
            </a-form-model-item>
            <a-form-model-item label="总重量" prop="(grossWeight">
                <a-input v-model="rowData.grossWeight" placeholder="总重量"></a-input>
            </a-form-model-item>
            <a-form-model-item label="运费" prop="(freight">
                <a-input v-model="rowData.freight" placeholder="运费"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editOrderShipInfo, selectByIdOrderShipInfo, addOrderShipInfo } from '../api/OrderShipInfoApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                id: [
                    { required: true, message: '请输入发货单', trigger: 'blur' }
                ],
                orderBelongsId: [
                    { required: true, message: '请输入（0代表三菱的发货单）所属经销商id', trigger: 'blur' }
                ],
                dealerId: [
                    { required: true, message: '请输入经销商id', trigger: 'blur' }
                ],
                shipCode: [
                    { required: true, message: '请输入发货单号', trigger: 'blur' }
                ],
                orderCode: [
                    { required: true, message: '请输入订单号', trigger: 'blur' }
                ],
                finterid: [
                    { required: true, message: '请输入k3wise销售订单', trigger: 'blur' }
                ],
                shipNum: [
                    { required: true, message: '请输入发货总数(创建发货单时的数量)', trigger: 'blur' }
                ],
                realShipNum: [
                    { required: true, message: '请输入实际发货数(erp回传)', trigger: 'blur' }
                ],
                deliveryId: [
                    { required: true, message: '请输入快递公司id', trigger: 'blur' }
                ],
                deliveryName: [
                    { required: true, message: '请输入快递公司名称', trigger: 'blur' }
                ],
                deliveryCode: [
                    { required: true, message: '请输入快递公司编码', trigger: 'blur' }
                ],
                deliveryNum: [
                    { required: true, message: '请输入快递单号', trigger: 'blur' }
                ],
                deliveryRemark: [
                    { required: true, message: '请输入物流备注', trigger: 'blur' }
                ],
                provinceId: [
                    { required: true, message: '请输入省_id', trigger: 'blur' }
                ],
                province: [
                    { required: true, message: '请输入省', trigger: 'blur' }
                ],
                cityId: [
                    { required: true, message: '请输入城市_id', trigger: 'blur' }
                ],
                city: [
                    { required: true, message: '请输入市', trigger: 'blur' }
                ],
                districtId: [
                    { required: true, message: '请输入区_id', trigger: 'blur' }
                ],
                district: [
                    { required: true, message: '请输入区', trigger: 'blur' }
                ],
                street: [
                    { required: true, message: '请输入详细街道地址', trigger: 'blur' }
                ],
                shipType: [
                    { required: true, message: '请输入收货人类型 1:个人 2:公司', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入收货人电话', trigger: 'blur' }
                ],
                imgUrl: [
                    { required: true, message: '请输入附件', trigger: 'blur' }
                ],
                createUser: [
                    { required: true, message: '请输入创建人', trigger: 'blur' }
                ],
                createUserId: [
                    { required: true, message: '请输入创建人id', trigger: 'blur' }
                ],
                createTime: [
                    { required: true, message: '请输入创建时间', trigger: 'blur' }
                ],
                modifyUser: [
                    { required: true, message: '请输入修改人', trigger: 'blur' }
                ],
                modifyUserId: [
                    { required: true, message: '请输入修改人id', trigger: 'blur' }
                ],
                modifyTime: [
                    { required: true, message: '请输入修改时间', trigger: 'blur' }
                ],
                flagDel: [
                    { required: true, message: '请输入是否删除', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
                version: [
                    { required: true, message: '请输入版本号', trigger: 'blur' }
                ],
                consignee: [
                    { required: true, message: '请输入收货人', trigger: 'blur' }
                ],
                actualPrice: [
                    { required: true, message: '请输入实际支付价', trigger: 'blur' }
                ],
                caseNumber: [
                    { required: true, message: '请输入件数', trigger: 'blur' }
                ],
                grossWeight: [
                    { required: true, message: '请输入总重量', trigger: 'blur' }
                ],
                freight: [
                    { required: true, message: '请输入运费', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addOrderShipInfo(this.rowData) : await editOrderShipInfo(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
